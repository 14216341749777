import { FormControl, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../Button';

const StyledFormControl = styled(FormControl)`
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  padding: ${({ theme }) => theme.spacing(1.2, 3.8)};
  position: sticky;
`;

export interface FormActionsProps extends PropsWithChildren {
  isSubmitting?: boolean;
  disabled?: boolean;
  onCancel?: (() => void) | false;
  cancelButtonLabel?: string;
  onSubmit?: () => void;
  submitButtonLabel?: string;
  alignButton?: 'left' | 'right';
  isSubmittingAllowed?: boolean;
  notAllowedMessage?: string;
}

export const FormActions = (props: FormActionsProps): JSX.Element => {
  const navigate = useNavigate();
  const {
    disabled,
    isSubmitting,
    onCancel,
    cancelButtonLabel = 'Cancel',
    onSubmit,
    children,
    submitButtonLabel = 'Save',
    alignButton = 'right',
    isSubmittingAllowed = true,
    notAllowedMessage = '',
  } = props;
  const goBackMethod = (): void => navigate(-1);
  const cancelMethod = onCancel || goBackMethod;
  const direction = alignButton === 'right' ? 'row' : 'row-reverse';

  return (
    <StyledFormControl margin="normal" fullWidth>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2} direction={direction}>
        {children ? (
          children
        ) : (
          <>
            {onCancel !== false && (
              <Grid item>
                <Button variant="text" onClick={cancelMethod} data-test="cancel-button">
                  {cancelButtonLabel}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                type="submit"
                color="primary"
                loading={isSubmitting}
                disabled={disabled}
                onClick={onSubmit}
                data-test="submit-button"
                allowed={isSubmittingAllowed}
                notAllowedMessage={notAllowedMessage}
              >
                {submitButtonLabel}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </StyledFormControl>
  );
};
