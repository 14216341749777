// checks if error message is realted to wrong passowrd or email
export const isWrongCredentialsMessage = (message?: string): boolean => {
  // teoretically it should always has error message, but sometimes it can be undefined for some reasons
  if (!message) {
    return false;
  }

  // until email enumerate protection is not enable for all environments we need to check also old way messages
  const isPasswordMessage = message.includes('wrong-password');
  const isUserMessage = message.includes('user-not-found');
  const areInvalidCredentials = message.includes('invalid-login-credentials') || message.includes('invalid-credential');

  return isPasswordMessage || isUserMessage || areInvalidCredentials;
};
