import { Grid, Typography } from '@mui/material';

import { Button, RHFAutoCompleteVirtualize } from '@openx/components/core';

import type { InstanceListOption } from '../types';

import { getEnvironmentConfigs } from 'environmentConfigs';
import { useCallback } from 'react';
import { useAuth } from './AuthContext';

type InstanceSelectProps = {
  instancesList: InstanceListOption[];
  disableSelect?: boolean;
  onButtonClick: (docsUrl: string) => void;
};

export const InstanceSelect = ({ instancesList, disableSelect, onButtonClick }: InstanceSelectProps) => {
  const { currentUser } = useAuth();
  const { env } = getEnvironmentConfigs();

  const docsUrl = env === 'prod' ? 'https://docs.openx.com' : `https://${env}-docs.openx.com`;

  const handleDocsButtonClick = useCallback(() => {
    onButtonClick(docsUrl);
  }, [docsUrl, onButtonClick]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Typography variant="h3" gutterBottom data-test="current-user">
        You are now logged in as {currentUser.email}
      </Typography>

      {!disableSelect && (
        <Typography variant="h3" gutterBottom data-test="authorize-app-text">
          Select instance
        </Typography>
      )}
      <Grid container spacing={3} marginTop={2} flexDirection="row" alignItems="center" justifyContent="space-between">
        <Grid item>
          <RHFAutoCompleteVirtualize
            name="instance"
            textFieldProps={{ label: 'Select instance' }}
            disableClearable
            renderOptions={{
              dataTest: 'instance-item',
            }}
            sx={{ marginBottom: 4, marginTop: 2, width: 300 }}
            getOptionLabel={option => option.label || ''}
            isOptionEqualToValue={(option, value) => {
              if (typeof value === 'string') {
                return value === option.label;
              }
              return value.id === option.id;
            }}
            options={instancesList}
            data-test="instance-list"
            disabled={disableSelect}
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleDocsButtonClick} data-test="docs-button">
            See API docs
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
