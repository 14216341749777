import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { TIMEOUT_BEFORE_REDIRECT } from 'config';

import { useAuth } from '../components/AuthContext';
import { SsoLayout } from '../components/SsoLayout';

import { EMAIL_FIELD, ForgotPasswordForm, type FormValues, validationSchema } from './ForgotPasswordForm';

const initialValues: FormValues = {
  [EMAIL_FIELD]: '',
};

export function ForgotPassword(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { sendPasswordResetEmail } = useAuth();

  const formMethods = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = useCallback(
    async (values: FormValues): Promise<void> => {
      try {
        const url = new URL(window.location.href);
        url.pathname = 'login';

        await sendPasswordResetEmail(values[EMAIL_FIELD]);
        enqueueSnackbar('An email with instructions to reset your password has been sent', { variant: 'success' });

        await new Promise(resolve => setTimeout(resolve, TIMEOUT_BEFORE_REDIRECT));

        navigate(`/login${location.search}${location.hash}`);
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      }
    },
    [sendPasswordResetEmail, enqueueSnackbar, location, navigate]
  );
  const onCancel = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <SsoLayout>
      <FormProvider {...formMethods}>
        <ForgotPasswordForm onSubmitHandler={formMethods.handleSubmit(handleSubmit)} onCancel={onCancel} />
      </FormProvider>
    </SsoLayout>
  );
}
