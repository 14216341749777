import { Route, type RouteProps, Routes } from 'react-router-dom';

import { PageNotFound } from '@openx/components/core';
import { Consent } from 'modules/auth/consent/Consent';
import { ForgotPassword } from 'modules/auth/forgotPassword/ForgotPassword';
import { InstanceSelectPage } from 'modules/auth/instanceSelect/InstanceSelectPage';
import { Login } from 'modules/auth/login/Login';
import { Main } from 'modules/auth/main/Main';
import { PostPasswordSetup } from 'modules/auth/postPasswordSetup/PostPasswordSetup';
import { UserManagement } from 'modules/auth/userManagement/UserManagement';

const routes: RouteProps[] = [
  {
    Component: Main,
    path: '/',
  },
  {
    element: <Login />,
    path: '/login',
  },
  {
    element: <ForgotPassword />,
    path: '/forgot-password',
  },
  {
    element: <UserManagement />,
    path: '/user-management',
  },
  {
    element: <Consent />,
    path: '/consent',
  },
  {
    element: <PostPasswordSetup />,
    path: '/password-setup',
  },
  {
    element: <InstanceSelectPage />,
    path: '/select-instance',
  },
  {
    element: <PageNotFound />,
    path: '*',
  },
];

const RoutesComponent = () => (
  <Routes>
    {routes.map((route, i) => (
      <Route key={i} {...route} />
    ))}
  </Routes>
);

export default RoutesComponent;
