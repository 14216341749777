import { Grid, Typography } from '@mui/material';
import type { FormEventHandler, MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { object, string } from 'yup';

import { Button, FormContainer, RHFTextField } from '@openx/components/core';

export interface ForgorPasswordFormProps {
  onSubmitHandler: FormEventHandler<HTMLFormElement>;
  onCancel: MouseEventHandler<HTMLElement>;
}

export const EMAIL_FIELD = 'email';
export interface FormValues {
  [EMAIL_FIELD]: string;
}
export const validationSchema = object().shape({
  [EMAIL_FIELD]: string().required('Email is required').email(),
});

export const ForgotPasswordForm = ({ onSubmitHandler, onCancel }: ForgorPasswordFormProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <>
      <Typography variant="h2" gutterBottom data-test="forgot-password-text">
        Forgot your password
      </Typography>
      <form onSubmit={onSubmitHandler} data-test="forgot-password-form">
        <FormContainer fullPage>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RHFTextField
                name={EMAIL_FIELD}
                type="email"
                label="Email"
                placeholder="Enter your email"
                required
                fullWidth
                margin="dense"
                autoComplete="username"
                data-test="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" alignItems="center" spacing={2} direction="row">
                <Grid item>
                  <Button variant="text" onClick={onCancel} data-test="cancel">
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    data-test="forgot-password"
                  >
                    Reset password
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormContainer>
      </form>
    </>
  );
};
