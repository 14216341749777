import { Paper as MUIPaper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type ReactNode, useMemo } from 'react';

import { defaultPadding, paddingMap, verticalPaddingFactor } from './config';
import type { PaperPadding, PaperProps, RenderProp } from './types';

const StyledMUIPaper = styled(MUIPaper, {
  shouldForwardProp: prop => prop !== 'gutterBottom' && prop !== 'gutterTop' && prop !== 'padding',
})<{ gutterBottom: boolean; gutterTop: boolean; padding: PaperPadding }>`
  margin-bottom: ${({ gutterBottom, theme }) => (gutterBottom ? theme.spacing(3) : 0)};
  margin-top: ${({ gutterTop, theme }) => (gutterTop ? theme.spacing(3) : 0)};
  padding: ${({ padding }) => ` ${paddingMap[padding] * verticalPaddingFactor}px ${paddingMap[padding]}px`};
  position: relative;
`;

const makeRenderChildren =
  (children: ReactNode): RenderProp =>
  () =>
    children;

export const Paper = (props: PaperProps): JSX.Element => {
  const { children, className, padding = defaultPadding, gutterBottom = false, gutterTop = false } = props;
  const paddingValue = paddingMap[padding];
  const renderChildren: RenderProp = useMemo(
    () => (typeof children === 'function' ? (children as RenderProp) : makeRenderChildren(children)),
    [children]
  );

  return (
    <StyledMUIPaper
      elevation={0}
      data-test={props['data-test']}
      className={className}
      gutterBottom={gutterBottom}
      gutterTop={gutterTop}
      padding={padding}
    >
      {renderChildren({ paddingValue })}
    </StyledMUIPaper>
  );
};
