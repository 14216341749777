import MuiTextField, { type StandardTextFieldProps } from '@mui/material/TextField';

export type TextFieldProps = Partial<StandardTextFieldProps> & { 'data-test'?: string };

export const TextField = (props: TextFieldProps): JSX.Element => {
  const defaultProps = {
    InputLabelProps: { disableAnimation: true, shrink: true },
    InputProps: { disableUnderline: true },
    'data-test': props['data-test'],
    variant: 'standard',
  } as TextFieldProps;

  return <MuiTextField {...defaultProps} {...props} />;
};
