import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import type { Query } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import i18n from 'i18next';
import type { SnackbarProvider } from 'notistack';

import { formatErrorMessage, isApiError } from './errors';

export const onQueryError = (
  error: Error,
  query: Query<unknown, unknown, unknown>,
  snackbar: SnackbarProvider | null
) => {
  // biome-ignore lint/suspicious/noConsole: we want to log errors
  console.error(error);

  if (query.meta?.disableNotification) {
    return;
  }

  const hasApiError = isAxiosError(error) && isApiError(error.response?.data);

  const apiErrorMessage = hasApiError && error.response?.data.message;
  const defaultErrorMessage = i18n.t('Fetching data failed.');
  const metaErrorMessage = query.meta?.errorMessage;

  const errorMessage = metaErrorMessage || apiErrorMessage || defaultErrorMessage;

  snackbar?.enqueueSnackbar(formatErrorMessage(errorMessage), {
    action: key => (
      <IconButton color="inherit" onClick={() => snackbar?.closeSnackbar(key)} size="large">
        <CloseIcon />
      </IconButton>
    ),
    variant: 'error',
  });
};
