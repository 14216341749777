import { CssBaseline, GlobalStyles, StyledEngineProvider, type Theme, ThemeProvider, css } from '@mui/material';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getEnvironmentConfigs } from 'environmentConfigs';
import { initializeApp } from 'firebase/app';
import { type Auth, browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth';
import type { SnackbarProvider as ISnackbarProvider, SnackbarOrigin } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { CloseNotificationButton, SnackbarProvider, theme } from '@openx/components/core';
import { queryClientOptions } from '@openx/constants';
import { onQueryError } from '@openx/utils/api/onQueryError';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { AuthProvider } from 'modules/auth/components/AuthContext';

import { APP_VERSION } from './config';
import Routes from './routes/Routes';

const globalStyles = (theme: Theme) => css`
  * {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
  }
  body {
    min-width: 1280px;
  }
  a {
    color: ${theme.palette.primary.main};
    text-decoration: none;
  }
`;

const snackBarProps: SnackbarOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
};

export const App = () => {
  const notistackRef = useRef<ISnackbarProvider>(null);
  const queryClient = useRef(
    new QueryClient({
      ...queryClientOptions,
      queryCache: new QueryCache({
        onError: (error, query) => onQueryError(error, query, notistackRef.current),
      }),
    })
  );

  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState<Auth | undefined>(undefined);
  const [envConfig, setEnvConfig] = useState<ReturnType<typeof getEnvironmentConfigs>>();

  useEffect(() => {
    const initAuth = async () => {
      try {
        const config = getEnvironmentConfigs();
        setEnvConfig(config);
        const app = initializeApp(config.firebase);
        const auth = getAuth(app);
        await setPersistence(auth, browserSessionPersistence);
        setAuth(auth);
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole: we want to log this error
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    initAuth();
    // biome-ignore lint/suspicious/noConsole: we want to log this information
    console.info(`%cLogin version: ${APP_VERSION}`, 'font-weight:bold;');
  }, []);

  const onClickDismiss = (key: string | number) => () => {
    notistackRef.current && notistackRef.current.closeSnackbar(key);
  };

  if (loading || !auth || !envConfig) {
    return null;
  }

  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient.current}>
        {envConfig.env !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}

        <ThemeProvider theme={theme}>
          <SnackbarProvider
            ref={notistackRef}
            anchorOrigin={snackBarProps}
            action={key => <CloseNotificationButton action={onClickDismiss(key)} />}
          >
            <BrowserRouter>
              <GlobalStyles styles={globalStyles} />
              <CssBaseline />
              <ErrorBoundary>
                <AuthProvider auth={auth}>
                  <Routes />
                </AuthProvider>
              </ErrorBoundary>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  );
};
