import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import { getEnvironmentConfigs } from 'environmentConfigs';

type RedirectToAuthorizeParams = {
  instanceUID?: string;
  redirectUri?: string;
  email?: string | null;
};

export const getCodeChallenge = (code_verifier: string) => {
  const base64Digest = Base64.stringify(sha256(code_verifier));

  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const generateRandomString = (length: number) => {
  const text = Math.random().toString(36);

  return text.substring(0, length);
};

export function getAuthURL(params?: RedirectToAuthorizeParams) {
  const redirectUri = params?.redirectUri ?? window.location.origin;
  const instanceUID = params?.instanceUID;
  const { apigee, env } = getEnvironmentConfigs();
  const defaultApigeeUrl = `https://${env || 'devint'}.api.openx.com`;
  const AUTH_URL = `${apigee.url || defaultApigeeUrl}/oauth2/v1/authorize`;
  const CODE_VERIFIER = generateRandomString(7);
  const SCOPE = 'openid email profile';
  const STATE = params?.email ? JSON.stringify({ email: params.email }) : 'no-state';
  const NONCE = `nonce-${generateRandomString(6)}`;
  const url = new URL(AUTH_URL);

  url.searchParams.set('scope', SCOPE);
  url.searchParams.set('response_type', 'code');
  url.searchParams.set('client_id', apigee.clientId);
  url.searchParams.set('redirect_uri', redirectUri || window.location.origin);
  url.searchParams.set('state', encodeURIComponent(STATE));
  url.searchParams.set('nonce', NONCE);
  url.searchParams.set('code_challenge', getCodeChallenge(CODE_VERIFIER));
  url.searchParams.set('code_challenge_method', 'S256');

  // authorization details
  if (instanceUID) {
    url.searchParams.set(
      'authorization_details',
      JSON.stringify([
        {
          identifier: instanceUID,
          type: 'instance_uid',
        },
      ])
    );

    sessionStorage.setItem('instanceUID', instanceUID);
  }

  return url;
}

export const redirectToAuthorize = (params?: RedirectToAuthorizeParams) => {
  const url = getAuthURL(params);

  window.location.replace(url.toString());
};
