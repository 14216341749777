import { upperFirst } from 'lodash';

import type { ApiError } from '@openx/types';

export type { ApiError, FieldValidationError, ValidationError } from '@openx/types';

export const formatErrorMessage = (message: string) => {
  const normalizedMessage = upperFirst(message.trim());

  return /[.!?]$/.test(normalizedMessage) ? normalizedMessage : `${normalizedMessage}.`;
};

export function isApiError(item: unknown): item is ApiError {
  if (!item || typeof item !== 'object') {
    return false;
  }

  return !!(item as ApiError).http_status;
}
