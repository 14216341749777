import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../components/AuthContext';
import { SsoLayout } from '../components/SsoLayout';
import { isWrongCredentialsMessage } from '../utils/isWrongCredentialsMessage';
import { isOAuthQueryValid } from '../utils/verifyOAuthParams';

import { EMAIL_FIELD, type FormValues, LoginForm, PASSWORD_FIELD, validationSchema } from './LoginForm';

const initialValues: FormValues = {
  [EMAIL_FIELD]: '',
  [PASSWORD_FIELD]: '',
};

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, signInWithEmailAndPassword } = useAuth();

  const formMethods = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (values: FormValues): Promise<void> => {
      try {
        await signInWithEmailAndPassword(values[EMAIL_FIELD], values[PASSWORD_FIELD]);
      } catch (error) {
        // we display general error message for wrong password or email and others errors
        const message = isWrongCredentialsMessage(error.message)
          ? 'Invalid username or password'
          : 'Something was wrong with login process. Please try again later.';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
    [signInWithEmailAndPassword, enqueueSnackbar]
  );

  const onForgotPasswordHandler = useCallback(
    () => navigate(`/forgot-password${location.search}${location.hash}`),
    [location, navigate]
  );

  if (currentUser && !isOAuthQueryValid(location)) {
    return <Navigate to={`/select-instance${location.search}${location.hash}`} />;
  }

  if (currentUser && isOAuthQueryValid(location)) {
    return <Navigate to={`/consent${location.search}${location.hash}`} />;
  }

  return (
    <SsoLayout>
      <FormProvider {...formMethods}>
        <LoginForm onSubmitHandler={formMethods.handleSubmit(onSubmit)} onForgotPassword={onForgotPasswordHandler} />
      </FormProvider>
    </SsoLayout>
  );
};
