import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorComponent, NotFoundImage } from './Error';

const StyledContainerError = styled(ErrorComponent)`
  height: 100% !important;
`;

const pageTitle = 'Page Not Found';

export const PageNotFound = (): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <StyledContainerError
      title={pageTitle}
      subtitle="We've looked everywhere and we just can't find that page in our universe."
      actions={[
        {
          children: 'Take me home please',
          color: 'secondary',
          'data-test': 'return-home',
          onClick: handleClick,
          variant: 'contained',
        },
      ]}
      PictureComponent={NotFoundImage}
      data-test="page-not-found"
    />
  );
};
