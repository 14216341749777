import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { FormControl, Tooltip, type TooltipProps, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

type Props = {
  fullWidth?: boolean;
  info: ReactNode;
  tooltipTop?: string;
  tooltipPlacement?: TooltipProps['placement'];
  children: ReactNode;
  margin?: 'dense' | 'normal' | 'none';
};

export function RHFFormFieldWithInfo({
  info,
  fullWidth = true,
  margin,
  tooltipPlacement = 'right',
  children,
  tooltipTop,
}: Props): JSX.Element | null {
  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      {children}
      <StyledTooltip title={<>{info}</>} placement={tooltipPlacement} tooltipTop={tooltipTop}>
        <IconButton size="small">
          <InfoRoundedIcon />
        </IconButton>
      </StyledTooltip>
    </FormControl>
  );
}

const StyledTooltip = styled(Tooltip, { shouldForwardProp: prop => prop !== 'tooltipTop' })<{
  tooltipTop?: string;
}>`
  position: absolute;
  right: -32px;
  top: ${({ tooltipTop }) => tooltipTop ?? '28px'};
`;
