import { ErrorOutline, Check } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';

const StyledCheck = styled(Check)`
  color: ${({ theme }) => theme.palette.success.main};
  margin-right: ${({ theme }) => theme.spacing(1.25)};
  transform: translateY(25%);
`;

const StyledErrorOutline = styled(ErrorOutline)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-right: ${({ theme }) => theme.spacing(1.25)};
  transform: translateY(25%);
`;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.palette.info.main};
    color: ${({ theme }) => theme.palette.info.contrastText};
  }
`;

interface DialogStatusIconProps {
  error?: string;
}

export function DialogStatusIcon({ error }: DialogStatusIconProps): JSX.Element {
  if (error) {
    return (
      <StyledTooltip title={error} data-test="error" placement="right">
        <StyledErrorOutline fontSize="small" />
      </StyledTooltip>
    );
  }

  return (
    <StyledTooltip title="Record succesfully updated." data-test="success" placement="right">
      <StyledCheck fontSize="small" />
    </StyledTooltip>
  );
}
