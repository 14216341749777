import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography, DialogTitle as MuiDialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode, SyntheticEvent } from 'react';

export interface DialogTitleProps {
  onClose?: (e: SyntheticEvent<HTMLElement>) => void;
  onCloseDisabled?: boolean;
  children?: ReactNode;
  dataTest?: string;
}

const StyledTitleContainerDiv = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledCloseIconButton = styled(IconButton)`
  margin: ${({ theme }) => theme.spacing(-1, 0, -1, 0)};
`;

export function DialogTitle(props: DialogTitleProps): JSX.Element {
  const { onClose, onCloseDisabled = false, children, dataTest = 'modal-title' } = props;

  return (
    <MuiDialogTitle>
      <StyledTitleContainerDiv data-test={dataTest}>
        <Typography component="h2" variant="h3">
          {children}
        </Typography>
        {onClose && (
          <StyledCloseIconButton aria-label="close" onClick={onClose} disabled={onCloseDisabled} size="large">
            <CloseIcon data-test="close-dialog" />
          </StyledCloseIconButton>
        )}
      </StyledTitleContainerDiv>
    </MuiDialogTitle>
  );
}
