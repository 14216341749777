import { Grid, Typography } from '@mui/material';

import { Button } from '@openx/components/core';
import { SsoLayout } from '../components/SsoLayout';

export const PostPasswordSetup = () => {
  return (
    <SsoLayout>
      <Typography variant="h2" gutterBottom data-test="header-text">
        New password setup
      </Typography>
      <Typography gutterBottom data-test="password-success-text">
        Password was set successfully.
      </Typography>
      <Grid container marginTop={4} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" data-test="go-to-login-button" href="/login">
            Go to login
          </Button>
        </Grid>
      </Grid>
    </SsoLayout>
  );
};
