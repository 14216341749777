import { Divider, Grid, type GridSize, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

import { StatusIndicator } from '../StatusIndicator';

const StyledWrapperDiv = styled('div')`
  width: 100%;
`;

const StyledContainerGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledStatusIndicator = styled(StatusIndicator)`
  display: block;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

export interface PageSectionProps {
  children?: ReactNode;
  divider?: boolean;
  title: string;
  status?: string;
  titleSize?: GridSize;
  description?: string | ReactNode;
}

export function PageSection({
  children,
  divider,
  title,
  status,
  description,
  titleSize = 3,
}: PageSectionProps): JSX.Element {
  return (
    <StyledWrapperDiv data-test={title.toLowerCase().replace(/\s/g, '-')}>
      <StyledContainerGrid container>
        <Grid item xs={titleSize}>
          <Typography data-test="title" variant="h2">
            {title}
          </Typography>
          {status && <StyledStatusIndicator status={status} data-test="status" />}
          {description}
        </Grid>
        {children}
      </StyledContainerGrid>
      {divider && <Divider />}
    </StyledWrapperDiv>
  );
}
