import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FullLoader } from '@openx/components/core';

import { useAuth } from '../components/AuthContext';

export const Main = () => {
  const navigate = useNavigate();
  const { currentUser, auth } = useAuth();

  useEffect(() => {
    if (!currentUser && auth) {
      navigate('/login');
    } else if (currentUser) {
      navigate('/select-instance');
    }
  }, [currentUser, auth, navigate]);

  return <FullLoader />;
};
