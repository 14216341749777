import { getEnvironmentConfigs } from 'environmentConfigs';
import type { User } from 'firebase/auth';

import type { InstanceListOption } from '../types';

import { getApigeeUrl } from './getUrl';
import type { FetchSessionInfoError } from './types';

type FetchInstancesListParams = {
  currentUser: User | null;
};

type InstancesListAPIResponse = {
  user_is_registered: boolean;
  instances_list: {
    [uid: string]: {
      api_hostname: string;
      admin_hostname: string[];
      name: string;
    };
  }[];
};

export type FetchInstancesListResponse = {
  error: FetchSessionInfoError | null;
  instancesList: InstanceListOption[] | null;
  registered: boolean | null;
};

export const transformInstancesListAPIResponse = (response: InstancesListAPIResponse): FetchInstancesListResponse => {
  const { instances_list, user_is_registered: registered } = response;
  const instancesList: InstanceListOption[] = instances_list.map(inst => {
    const key = Object.keys(inst)[0];
    const urls = inst[key].admin_hostname.map(hostname => `https://${hostname}`);

    return {
      id: key,
      label: inst[key].name,
      urls,
    };
  });

  return {
    error: null,
    instancesList,
    registered,
  };
};

export const fetchInstancesListResponse = async ({
  currentUser,
}: FetchInstancesListParams): Promise<FetchInstancesListResponse> => {
  if (!currentUser) {
    return {
      error: {
        message: 'Unauthenticated',
        name: 'Error',
        type: 'authentication',
      },
      instancesList: null,
      registered: null,
    };
  }

  const idToken = await currentUser.getIdToken();
  const { apigee } = getEnvironmentConfigs();
  const url = getApigeeUrl(apigee.instancesListPath);

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  const { status, statusText } = response;

  if (!response.ok) {
    if (status !== 403) {
      return {
        error: {
          message: `Unknown error ${status}:${statusText}`,
          name: 'Error',
          type: 'unknown',
        },
        instancesList: null,
        registered: null,
      };
    }
    return {
      error: {
        message: 'Authorization error',
        name: 'Error',
        type: 'authorization',
      },
      instancesList: null,
      registered: null,
    };
  }

  try {
    const instanceListAPIResponse: InstancesListAPIResponse = await response.json();
    return transformInstancesListAPIResponse(instanceListAPIResponse);
  } catch (err) {
    return {
      error: {
        message: `Unknown error ${err.message}`,
        name: 'Error',
        type: 'unknown',
      },
      instancesList: null,
      registered: null,
    };
  }
};
