import type { FilterOptionsState } from '@mui/material/useAutocomplete';

import type { Option } from '@openx/types';

export interface SortedOptions extends Option {
  subCategories?: Option[];
}

export function getCategoryOptionLabel(optionId: string, option?: Option) {
  return option ? `[${optionId}] ${option.name}` : optionId;
}

export function getItemPadding(level?: number | string) {
  return level ? (Number(level) - 1) * 2 : 0;
}

const BANNED_CATEGORY_ID = '98';

const getParentLevelId = ({ id, cat_level }: Option) =>
  Number(Number(cat_level) === 1 ? id : id.substring(0, id.length - 2));
const getChildLevelId = ({ id, cat_level }: Option) =>
  Number(Number(cat_level) === 1 ? -1 : id.substring(id.length - 2, id.length));

export const prepareSortedNestedCategoriesIds = (options: Option[]) => {
  return options
    .reduce<Option[]>((acc, option) => {
      option.id = String(option.id);

      if (option.id.length <= 4) {
        // In Market Tool category option for ban come without cat level
        if (option.id === BANNED_CATEGORY_ID) {
          option.cat_level = 1;
        }

        acc.push(option);
      }

      return acc;
    }, [])
    .sort((a, b) => {
      if (getParentLevelId(a) === getParentLevelId(b)) {
        return getChildLevelId(a) > getChildLevelId(b) ? 1 : -1;
      }

      return getParentLevelId(a) > getParentLevelId(b) ? 1 : -1;
    })
    .map(category => category.id);
};

export const prepareCategoryMapWithSubcategories = (options: Option[]) =>
  options.reduce<Record<string, SortedOptions>>((acc, option) => {
    if (String(option.cat_level) === '1' || option.id === BANNED_CATEGORY_ID) {
      acc[option.id] = {
        ...acc[option.id],
        ...option,
      };
    }

    if (option.parent) {
      acc[option.parent] = {
        ...acc[option.parent],
        subCategories: [...(acc[option.parent]?.subCategories || []), option],
      };
    }
    return acc;
  }, {});

export const prepareNestedCategoriesFilter =
  (map: Record<string, Option>, mapWithSubcategories: Record<string, SortedOptions>) =>
  (options: string[], { inputValue }: FilterOptionsState<string>) => {
    if (!inputValue) {
      return options;
    }

    return options.filter(optionId => {
      const option = map[optionId];

      if (!option) {
        return false;
      }

      const parentId = option.parent;
      const catLevel = String(option.cat_level);
      const name = option.name.toLowerCase();
      const value = inputValue.toLowerCase();

      // Show main category when include search value or contain subcategories with search value
      if (catLevel === '1') {
        if (name.includes(value)) {
          return true;
        }

        const children = mapWithSubcategories[optionId].subCategories;
        return children?.some(opt => opt.name.toLowerCase().includes(value));
      }

      // Show all subcategories when search value contain parent name
      if (parentId && map[parentId]?.name.toLowerCase().includes(value)) {
        return true;
      }

      return name.includes(value);
    });
  };
