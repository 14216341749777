import { useCallback, useState } from 'react';

import { Button, ErrorComponent, FailureImage, FormContainer, FullLoader } from '@openx/components/core';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import type { Shape } from '@openx/types/src/lib/yupHelpers';
import { QueryKeys } from '@openx/utils/api/queryKeys';
import { useQuery } from '@tanstack/react-query';
import has from 'lodash/has';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { type ObjectSchema, object } from 'yup';
import { useAuth } from '../components/AuthContext';
import { InstanceSelect } from '../components/InstanceSelect';
import { SsoLayout } from '../components/SsoLayout';
import type { InstanceListOption } from '../types';
import { fetchInstancesListResponse } from '../utils/fetchInstancesListResponse';
import { redirectToAuthorize } from '../utils/redirectToAuthorize';

type ErrorWithDescription = {
  error?: string;
  errorDescription?: string | null;
};
type ResponseOIDCError = (Error & ErrorWithDescription) | ErrorWithDescription;

type FormValues = {
  instance: InstanceListOption | null;
};

const validationSchema = object({
  instance: object<null, Shape<InstanceListOption>>()
    .nullable()
    .required('Please select an instance')
    .test({
      message: 'Please select an instance',
      name: 'instance',
      test(value) {
        if (!value || !has(value, 'id')) {
          return false;
        }
        return true;
      },
    }),
}) as ObjectSchema<FormValues>;

export const InstanceSelectPage = () => {
  const [error, setError] = useState<ResponseOIDCError | null>(null);
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const {
    data: instancesList,
    isLoading,
    error: queryError,
  } = useQuery({
    meta: {
      errorMessage: 'Failed to fetch instance list',
    },
    queryFn: async () => {
      const response = await fetchInstancesListResponse({
        currentUser,
      });

      if (response.error) {
        setError(response.error);
      }

      return response.instancesList;
    },
    queryKey: [QueryKeys.LOGIN_APP_INSTANCE_LIST, currentUser?.uid],
    retry: false,
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      instance: null,
    },
    resolver: yupResolver<FormValues>(validationSchema),
  });

  const handleLogout = useCallback(async () => {
    setError(null);
    await signOut();
    sessionStorage.clear();
    navigate('/');
  }, [signOut, navigate]);

  const onSubmit: SubmitHandler<FormValues> = data => {
    if (!data.instance) {
      return;
    }

    redirectToAuthorize({
      instanceUID: data.instance.id,
      redirectUri: `${data.instance.urls[0]}/response-oidc`,
      email: currentUser?.email,
    });
  };

  const handleDocsButtonClick = useCallback((docsUrl: string) => {
    window.location.href = docsUrl;
  }, []);

  if (error || queryError) {
    const description = error?.errorDescription ? `: ${error.errorDescription}` : `: ${queryError?.message ?? ''}`;
    const subtitle = 'Something went wrong while attempting to sign you in' + description;

    return (
      <ErrorComponent
        title="Authorization error"
        subtitle={subtitle}
        PictureComponent={FailureImage}
        data-test="authorization-error"
        actions={[
          {
            children: 'Log out',
            color: 'secondary',
            'data-test': 'button-log-in',
            onClick: handleLogout,
            variant: 'contained',
          },
        ]}
      />
    );
  }

  if (instancesList) {
    return (
      <SsoLayout>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <FormContainer fullPage>
              <InstanceSelect
                instancesList={instancesList}
                disableSelect={false}
                onButtonClick={handleDocsButtonClick}
              />
              <Grid container justifyContent="space-between" alignItems="center" spacing={2} direction="row">
                <Grid item>
                  <Button variant="text" color="primary" disabled={false} onClick={handleLogout} data-test="logout">
                    Log out
                  </Button>
                </Grid>
                <Grid item>
                  <Box alignSelf="flex-end">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={!formMethods.formState.isValid || formMethods.formState.isSubmitting || isLoading}
                      data-test="authorize-button"
                    >
                      Continue
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </FormContainer>
          </form>
        </FormProvider>
      </SsoLayout>
    );
  }

  return <FullLoader />;
};
