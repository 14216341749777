import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress';
import { styled, css } from '@mui/material/styles';

const StyledLoaderContainerDiv = styled('div')<{
  hasPadding: boolean;
}>`
  text-align: center;

  ${({ hasPadding, theme }) =>
    hasPadding &&
    css`
      padding: ${theme.spacing(4)};
    `}
`;

interface LoaderProps extends CircularProgressProps {
  noPadding?: boolean;
}

export const Loader = (props: LoaderProps): JSX.Element => {
  const { noPadding, ...restProps } = props;

  return (
    <StyledLoaderContainerDiv hasPadding={!noPadding} data-test={props['data-test']}>
      <CircularProgress color="primary" {...restProps} />
    </StyledLoaderContainerDiv>
  );
};
