export interface EnvironmentConfigs {
  env: string;
  firebase: { apiKey: string; authDomain: string };
  defaultRedirectURI: string;
  requireConsent: boolean;
  apigee: {
    url: string;
    sessionInfoPath: string;
    instancesListPath: string;
    consentPath: string;
    resetPasswordPath: string;
    clientId: string;
  };
  newCustomerInit: {
    url: string;
    instanceListPath: string;
  };
}

declare global {
  interface Window {
    __CONFIG__: EnvironmentConfigs;
  }
}

export function getEnvironmentConfigs() {
  return window.__CONFIG__ as EnvironmentConfigs;
}
